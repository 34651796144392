import { ClientApplication, createApp } from "@shopify/app-bridge"
import { ContextualSaveBar, Fullscreen, Modal, NavigationMenu, Redirect, TitleBar } from "@shopify/app-bridge/actions"
import { isShopifyEmbedded as _isShopifyEmbedded } from "@shopify/app-bridge-utils/utilities/platform"

const urlSearchParams = new URLSearchParams(window.location.search)

type TypeAppBridgeInstance = {
  titleBar: TitleBar.TitleBar | undefined
  contextualSaveBar: ContextualSaveBar.ContextualSaveBar | undefined
}

const AppBridgeInstance: TypeAppBridgeInstance = {
  titleBar: undefined,
  contextualSaveBar: undefined,
}

type SaveBarActionsProps = {
  saveAction: {
    content: string,
    onAction: () => void,
  },
  discardAction: {
    content: string,
    onAction: () => void,
  }
}

window.ManageParams = {
  "auth-token": urlSearchParams.get("auth-token") || "",
  // @ts-ignore
  display_intercom: urlSearchParams.get("display_intercom") || "false",
  from: urlSearchParams.get("from") || "",
}

export function isAdmin() {
  return window.ManageParams.from === "admin"
}

export function isShopifyEmbedded() {
  return !isAdmin() && _isShopifyEmbedded()
}

export default class AppBridge {
  public static app: ClientApplication<any>
  public static redirect: Redirect.Redirect
  public static navigationMenu: NavigationMenu.NavigationMenu

  static dispatchFullscreen(action: Fullscreen.Action) {
    this.fullscreen()
      .dispatch(action)
  }

  static fullscreen() {
    return Fullscreen.create(this.app)
  }

  /**
   * 判断是否存在该实例
   */
  static exist() {
    return this.app !== undefined
  }

  static set(host: string) {
    const apiKey = import.meta.env.VITE_SHOPIFY_APP_KEY || ""

    this.app = createApp({ apiKey, host })

    if (!this.redirect) {
      this.redirect = Redirect.create(this.app)
    }


    return this.app
  }

  static modal() {
    return Modal.create(this.app, {
      id: "app-bridge-default-modal",
      message: "Default modal message",
      title: "Default modal title",
    })
  }


  static dispatchModal(action: Modal.Action) {
    this.modal()
      .dispatch(action)
  }

  static titleBar() {
    if (AppBridgeInstance.titleBar === undefined) {
      AppBridgeInstance.titleBar = TitleBar.create(this.app, { title: "Default title" })
    }

    return AppBridgeInstance.titleBar
  }

  static contextualSaveBar(actions: SaveBarActionsProps) {
    const { saveAction, discardAction } = actions

    if (AppBridgeInstance.contextualSaveBar === undefined) {
      AppBridgeInstance.contextualSaveBar = ContextualSaveBar.create(this.app, {
        saveAction: {
          label: saveAction.content,
          disabled: false,
          loading: false,
        },
        discardAction: {
          label: discardAction.content,
          disabled: false,
          loading: false,
          discardConfirmationModal: false,
        },
        leaveConfirmationDisable: true,
      })
    }

    AppBridgeInstance.contextualSaveBar.subscribe(ContextualSaveBar.Action.DISCARD, function () {
      // Do something with the discard action
      typeof discardAction.onAction === "function" && discardAction.onAction()
      // Hide the contextual save bar
      if (AppBridgeInstance.contextualSaveBar !== undefined) {
        AppBridgeInstance.contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE)
      }
      return
    })

    AppBridgeInstance.contextualSaveBar.subscribe(ContextualSaveBar.Action.SAVE, function () {
      // Do something with the save action
      typeof discardAction.onAction === "function" && saveAction.onAction()
      // Hide the contextual save bar
      // if(AppBridgeInstance.contextualSaveBar !== undefined) {
      //   AppBridgeInstance.contextualSaveBar.dispatch(ContextualSaveBar.Action.HIDE);
      // }
    })

    return AppBridgeInstance.contextualSaveBar
  }


  static setNavigationMenu(navigationMenu: NavigationMenu.NavigationMenu) {
    this.navigationMenu = navigationMenu
  }
}


const getAppBase = () => import.meta.env.VITE_BASE_URL || "/"

const getAppLink = (path: string = "/") => {
  const link = `${getAppBase()}${path}`

  return link.replaceAll("//", "/")
}

export {
  getAppBase,
  getAppLink,
}
